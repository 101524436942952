import React from "react"
import Layout from "../components/Layout"
import { Box, Flex, Text, Button } from "rebass"
import { Label, Input, Textarea } from "@rebass/forms"

export default function Contact() {
  return (
    <Layout>
      <Flex flexDirection={["column", "row"]} justifyContent="center">
        {/* <Box width={[1, 4 / 7]}>
          <Image
            src="https://static1.squarespace.com/static/56d4a125e707eb7a02e4055f/t/56d600622eeb816ca61bf65f/1456865384899/30.jpg?format=750w"
            mb={[16, 64]}
            sx={{
              width: ["100%"],
            }}
          />
        </Box> */}
        <Box mb={16} width={[1, 3 / 7]}>
          <Flex pl={[4, 0]} pr={[4, 0]} flexDirection="column">
            <Text
              fontSize={14}
              mb={16}
              sx={{
                color: "mainForeground",
                fontFamily: "body",
                fontWeight: "body",
                lineHeight: "body",
                textTransform: "uppercase",
                letterSpacing: "0.06em",
                fontSize: "16",
              }}
            >
              Contact
            </Text>
            <Text variant="content">
              Feel free to contact me with any enquiries, commissions or even
              just for a chat using the appended form.
            </Text>
            <Text variant="content">
              Alternatively, feel free to email me at info@gregwoodwardphoto.com
              or call me at 07921085400.
            </Text>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <Box>
                <Label htmlFor="name">Name:</Label>
                <Input type="text" id="name" name="name" />
              </Box>
              <Box>
                <Label htmlFor="email">Email:</Label>
                <Input type="email" id="email" name="email" />
              </Box>
              <Box mb={16}>
                <Label htmlFor="message">Message:</Label>
                <Textarea id="message" name="message"></Textarea>
              </Box>
              <Box>
                <Button
                  fontSize={12}
                  type="submit"
                  variant="outline"
                  sx={{ width: "100%", cursor: "pointer" }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  )
}
